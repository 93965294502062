import { Location } from './../models/location';
import { Organization, Organization_Location } from './../models/organization';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Venue } from '../models/venue';
@Injectable({
  providedIn: 'root'
})
export class LocationManagementService {

  userRoles: Array<string>;
  // received variable
  locationId: string;
  organizationsList: Organization[];
  venuesList: Venue[];

  constructor(
    private auth: AuthService, private afs: AngularFirestore,
  ) {
    auth.user$.pipe(
      map(user => {
        return this.userRoles = _.keys(_.get(user, 'roles'));
      })).subscribe();
  }

  // Get locations data
  async getLocationList() {
    return new Promise<any>((resolve, reject) => {
      firebase.firestore().collection('locations').where('is_deleted', '==', false).get().then(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );
    });
  }

  // Get single location
  getSingleLocation(key) {
    return this.afs.collection('locations').doc(key).get();
  }

  //  Authorize
  get canCreate(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }

  get canRead(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }
  get canUpdate(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }
  get canDelete(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }

  // Helper to determine if any matching roles exists
  private matchingRole(allowedRoles): boolean {
    return !_.isEmpty(_.intersection(allowedRoles, this.userRoles));
  }

  // Actions
  viewMap(elementId) {
    this.locationId = elementId;
  }

  // Filtering
  filterByEvents(locationId) {
    return new Promise<any>((resolve, reject) => {
      firebase.firestore().collection('events').where('location', "==", locationId).get().then(
        (res) => {
          resolve(res);
        }, error => {
          reject(error);
        }
      );
    });
  }

  filterByVenues(locationId) {
    return new Promise<any>((resolve, reject) => {
      firebase.firestore().collection('venues').where("location_id", "==", locationId).get().then(
        (res) => {
          resolve(res);
        }, error => {
          reject(error);
        }
      );
    });
  }

  filterByOrganizations(localId) {
    const db = firebase.firestore();
    return new Promise<any>((resolve, reject) => {
      db.collectionGroup('offices').where('office_location', '==', localId).get().then((res) => {
        // get data
        if (res.docs.length > 0) {
          const groupByParents = _.groupBy(res.docs, (doc) => {
            return doc.ref.parent.parent.id;
          });
          resolve(groupByParents);
        }
      }, err => {
        reject(err);
      });
      // console.log(query);

    });
  }

  deleteLocation(locationId) {
    const locationRef: AngularFirestoreDocument<Location> = this.afs.doc(`locations/${locationId}`);
    return locationRef.update({ is_deleted: true });
  }

}
