import { AuthService } from '../../../../core/services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  private readonly notifier: NotifierService;

  registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });
  constructor(private auth: AuthService, private router: Router, notifierService: NotifierService) {
    this.notifier = notifierService;
  }

  ngOnInit() {
  }

  signUp(formValue) {
    const emailSignUp = formValue.email;
    const passwordSignUp = formValue.password;
    this.auth.emailSignUp(emailSignUp, passwordSignUp).then(res => {
      if (res.user) {
        this.notifier.notify('success', 'Sign up successfully !');
        // auto log on user
        const data = {
          email: res.user.email,
          password: passwordSignUp
        };
        this.auth.emailLoggin(data).then(res => {
          if (res) {
            this.router.navigate(['/dashboard/statistic']);
          }
        });
      }
    }).catch(err => {
      if (err.code == "auth/email-already-in-use") {
        this.notifier.notify('warning', err.message);
      }
    })
  }
}
