import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  /*
  * Call from venues
  */
  calledFromVenue = false;
  @Input() set isFromVenue(status: boolean) {
    // tslint:disable-next-line:no-unused-expression
    if (status === true) {
      this.calledFromVenue = true;
      const path = `venues/${Date.now()}_${this.file.name}`;
      this.UploadProcess(path);
    } else {
      this.calledFromVenue = false;
    }
  }
  isEditted = false;
  @Input() set isEditFromVenue(status: boolean) {
    // tslint:disable-next-line:no-unused-expression
    if (status === true) {
      this.isEditted = true;
      const path = `venues/${Date.now()}_${this.file.name}`;
      this.UploadProcess(path);
    } else {
      this.isEditted = false;
    }
  }
  @Input() file: File;
  @Output() emitImg = new EventEmitter();
  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: any;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore) { }

  ngOnInit() {
  }


  UploadProcess(path) {
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        this.downloadURL.subscribe(url => {
          this.emitImg.emit(url);
        });
      })
    ).subscribe();
  }
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}
