import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor() {}

  // Listen if any files are dropped in
  public filesDrop = new BehaviorSubject(0);
  // Listen to single image url
  public imgSingle = new BehaviorSubject<any>('');

  // Interaction
  public getTotalCount(val) {
    this.filesDrop.next(val);
  }
  public getSingleImgUrl(val) {
    this.imgSingle.next(val);
  }
}
