import { LocationManagementService } from '../../../core/services/location-management.service';
import { Component, OnInit } from '@angular/core';
import { Organization } from '../../../core/models/organization';
import { OrganizationsManagementService } from '../../../core/services/organizations-management.service';
import { marker } from '../../../core/models/marker';
import { Venue } from '../../../core/models/venue';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {

  lat: number;
  lng: number;
  filter = false;
  optionsFilter: string[] = ['Events', 'Venues', 'Organizations'];
  optionPicked: string;
  // Filter organization
  executedOrganizationFilter = false;
  filterOrganizationStat = false;
  organizationList: Organization[] = [];

  // Filter events
  executedEventFilter = false;
  filterByEventsStat = false;
  eventsList = [];
  // Filter venues
  executedVenuesFilter = false;
  filterbyVenues = false;
  venuesList: Venue[] = [];
  // markers
  markers: marker[] = [];
  eventsMakersLocation: marker[] = [];
  organizationMarkers: marker[] = [];
  // Carousel
  currentIndex = 0;
  speed = 5000;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  autoplay = true;
  // constructor
  constructor(
    private LocationManagementService: LocationManagementService,
    private organizationManagementService: OrganizationsManagementService
  ) {
  }

  ngOnInit() {
    // Get geopoint from service
    if (this.LocationManagementService.locationId) {
      // Retreive data
      this.LocationManagementService.getSingleLocation(this.LocationManagementService.locationId).subscribe((res) => {
        this.lat = res.data().location_geopoint.latitude;
        this.lng = res.data().location_geopoint.longitude;
      }, err => {
        console.log(err);
      });
    }
    // default filter the event lists on load
    this.optionPicked = 'Events';
    this.filterEvents();

  }
  filterOrganizations() {
    this.filterOrganizationStat = true;
    this.filter = true;
    this.filterbyVenues = false;
    this.filterByEventsStat = false;
    if (!this.executedOrganizationFilter) {
      this.executedOrganizationFilter = true;
      // reset the markers
      this.markers.length = 0;
      this.LocationManagementService.filterByOrganizations(this.LocationManagementService.locationId).then((res) => {
        // console.log(res); 
        let data = res;
        let organizationRetrieved: any = {};
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            this.organizationManagementService.getSingleOrganization(key).subscribe((parent) => {

              const offices = data[key];
              organizationRetrieved = {
                id: parent.id,
                feature_img: parent.data().feature_img,
                name: parent.data().name,
                users: parent.data().users,
                category: parent.data().category,
                locations: offices
              };

              this.organizationList.push(organizationRetrieved);

              // Set offices markers
              offices.forEach(office => {
                // tslint:disable-next-line:no-shadowed-variable
                let marker: marker;
                marker = {
                  lat: office.data().office_geopoint.latitude,
                  lng: office.data().office_geopoint.longitude,
                  label: organizationRetrieved.name,
                  img: organizationRetrieved.feature_img,
                  contact: office.data().phone,
                  draggable: true
                };
                this.markers.push(marker);
              });
            });
          }

        }
      });

    }
  }
  filterEvents() {
    this.filterByEventsStat = true;
    this.filter = true;
    this.filterOrganizationStat = false;
    this.filterbyVenues = false;
    // reset markers
    this.markers.length = 0;
    if (!this.executedEventFilter) {
      this.executedEventFilter = true;
      this.LocationManagementService.filterByEvents(this.LocationManagementService.locationId).then((res) => {
        this.eventsList = res.docs;
        for (const ev of this.eventsList) {
          let eventsLocationMarker: marker = {
            lat: ev.data().venue.venue_geopoint.latitude,
            lng: ev.data().venue.venue_geopoint.longitude,
            label: ev.data().event_name,
            draggable: true,
            img: ev.data().venue.feature_img,
            contact: ev.data().venue.venue_contact
          }
          this.markers.push(eventsLocationMarker);
        }
      });
    }
  }
  filterVenues() {
    this.filterbyVenues = true;
    // reset markers
    this.markers.length = 0;
    this.filterByEventsStat = false;
    this.filterOrganizationStat = false;
    this.filter = true;
    if (!this.executedVenuesFilter) {
      this.executedVenuesFilter = true;
      this.LocationManagementService.filterByVenues(this.LocationManagementService.locationId).then((res) => {
        this.venuesList = res.docs;
        if (this.venuesList.length > 0) {
          for (const venue of this.venuesList) {
            let marker: marker;
            marker = {
              lat: venue.data().venue_geopoint.latitude,
              lng: venue.data().venue_geopoint.longitude,
              label: venue.data().venue_name,
              img: venue.data().venue_images[0],
              contact: venue.data().venue_contact,
              draggable: true
            };
            this.markers.push(marker);
          }

        }
      });
    }
  }

  // Filter
  filterSelected(option) {
    switch (option) {
      case 'Organizations':
        this.filterOrganizations();
        break;

      case 'Venues':
        this.filterVenues();
        break;

      case 'Events':
        this.filterEvents();
        break;

      default:
        this.filterEvents();
        break;
    }
  }

}

