import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

  transform(roles: any, args?: any): any {
    const rolesName = [];
    const entries = Object.entries(roles);
    for (const [role, value] of entries) {
      if (role === 'admin') {
        // tslint:disable-next-line:no-unused-expression
        value === true ? rolesName.push(role) : null;
      }
      if (role === 'editor') {
        // tslint:disable-next-line:no-unused-expression
        value === true ? rolesName.push(role) : null;
      }
      if (role === 'viewer') {
        // tslint:disable-next-line:no-unused-expression
        value === true ? rolesName.push(role) : null;
      }
    }
    return rolesName;
  }

}
