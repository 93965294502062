import { UserManagementService } from './user-management.service';
import { Organization } from './../models/organization';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsManagementService {

  userRoles: Array<string>;
  // received variables
  officeId: string;
  organizationPicked: Organization;
  orgPickedToEdit: Organization;
  // own state
  addedSuccess = false;
  removeSuccess = false;

  constructor(
    private auth: AuthService,
    private db: AngularFireDatabase,
    private afs: AngularFirestore,
    private usersService: UserManagementService
  ) {
    auth.user$.pipe(
      map(user => {
        return this.userRoles = _.keys(_.get(user, 'roles'));
      })).subscribe();
  }

  // Get data
  getOrganizationsList() {
    return new Promise<any>((resolve, reject) => {
      firebase.firestore().collection('organizations').get().then(
        (res) => {
          resolve(res);
        }, err => reject(err));
    });

  }

  getSingleOrganization(key) {
    return this.afs.collection('organizations').doc(key).get();
  }

  getSubcollection(key) {
    return this.afs.collection('organizations').doc(key).collection('offices').valueChanges();
  }

  getOrganizationsOffices(key): Observable<any> {
    return this.afs.collection('organizations').doc(key).collection("offices").get();
  }

  getUserWithNoOrganization() {
    return this.afs.collection('users', ref => ref.where('has_organization', "==", false)).get();
  }
  // Authorization
  get canRead(): boolean {
    const allowed = ['admin', 'editor', 'viewer'];
    return this.matchingRole(allowed);
  }
  get canCreate(): boolean {
    const allowed = ['admin', 'editor'];
    return this.matchingRole(allowed);
  }
  get canUpdate(): boolean {
    const allowed = ['admin', 'editor'];
    return this.matchingRole(allowed);
  }
  get canDelete(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }

  get canAssignUserToOrganization(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }
  // Helper to determine if any matching roles exists
  private matchingRole(allowedRoles): boolean {
    return !_.isEmpty(_.intersection(allowedRoles, this.userRoles));
  }

  // User actions
  viewMap(elementId) {
    this.officeId = elementId;
  }

  AddToOrganization(userData, organization) {
    const dataPush = {
      displayName: userData.displayName,
      email: userData.email,
      photoURL: userData.photoURL,
      uid: userData.uid
    };
    const orgRef = this.afs.collection('organizations').doc(organization.id);
    orgRef.update({
      users: firebase.firestore.FieldValue.arrayUnion(dataPush)
    });
    this.addedSuccess = true;
    // also update the user joining status
    this.usersService.UpdateUserJoiningStatus(userData, true);
  }

  RemoveFromOrg(userData, organization) {
    const orgRef = this.afs.collection('organizations').doc(organization.id);
    orgRef.update({
      users: firebase.firestore.FieldValue.arrayRemove(userData)
    });
    this.removeSuccess = true;
    // update the user joining status
    this.usersService.UpdateUserJoiningStatus(userData, false);
  }

  DeleteOrg(organizationId) {
    if (this.canDelete) {
      return new Promise<any>((resolve, reject) => {
        const orgRef: AngularFirestoreDocument<Organization> = this.afs.doc(`organizations/${organizationId}`);
        return orgRef.update({is_deleted: true});
      });
    }
  }
}
