import { Venue } from '../models/venue';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class VenuesManagementService {
  constructor(private auth: AuthService, private db: AngularFireDatabase, private afs: AngularFirestore) {
    auth.user$.pipe(
      map(user => {
        return this.userRoles = _.keys(_.get(user, 'roles'));
      })).subscribe();
  }

  // Authorization
  get canRead(): boolean {
    const allowed = ['admin', 'editor', 'viewer'];
    return this.matchingRole(allowed);
  }
  get canCreate(): boolean {
    const allowed = ['admin', 'editor'];
    return this.matchingRole(allowed);
  }
  get canUpdate(): boolean {
    const allowed = ['admin', 'editor'];
    return this.matchingRole(allowed);
  }
  get canDelete(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }
  userRoles: Array<string>;
  // received variable
  venueViewMap: any;

  // subsribe changes to images
  public subscribeImgEmitter = new BehaviorSubject<any>(null);
  public hasFiles = new BehaviorSubject(0);
  public hasFilesFromInput = new BehaviorSubject(0);
  public removeImg = new BehaviorSubject<any>([]);
  public getImgsUrl(val) {
    this.subscribeImgEmitter.next(val);
  }
  public checkImages(val) {
    this.hasFiles.next(val);
  }
  public checkImgFromInput(val) {
    this.hasFilesFromInput.next(val);
  }
  public checkImagesRemove(val) {
    this.removeImg.next(val);
  }
  // Get data
  async getVenuesList() {
    return new Promise<any>((resolve, reject) => {
      firebase.firestore().collection('venues').where('is_deleted', '==', false).get().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getSingleVenue(key) {
    return this.afs.collection('venues').doc(key).get();
  }

  getCategories() {
    return new Promise<any>((resolve, reject) => {
      firebase.firestore().collection('venues_categories').get().then(
        (res) => {
          resolve(res);
        }, error => {
          reject(error);
        }
      );
    });
  }
  // Helper to determine if any matching roles exists
  private matchingRole(allowedRoles): boolean {
    return !_.isEmpty(_.intersection(allowedRoles, this.userRoles));
  }

  // User actions
  AddVenue(venueData) {
    if (this.canCreate) {
      return new Promise<any>((resolve, reject) => {
        this.afs.collection('venues').add(venueData)
          .then((res) => resolve(res))
          .catch(err => reject(err));
      });
    }
  }

  // Edit venue
  EditVenue(venueData, venueId) {
    if (this.canUpdate) {
      const venueRef: AngularFirestoreDocument<Venue> = this.afs.doc(`venues/${venueId}`);
      const removeSubscription = this.removeImg.subscribe(val => {
        if (val.length > 0) {
          val.forEach(img => {
            this.DeleteImgFromStorage(img);
          });
        }
      });
      removeSubscription.unsubscribe();
      return venueRef.set(venueData, { merge: true });
    }
  }
  // Flagged deletion on venue
  DeleteVenue(venueId) {
    if (this.canDelete) {
      const venueRef: AngularFirestoreDocument<Venue> = this.afs.doc(`venues/${venueId}`);
      return venueRef.update({ is_deleted: true });
    }
  }
  // Delete img from storage
  DeleteImgFromStorage(itemName) {
    itemName = decodeURI(itemName);
    return new Promise<any>((resolve, reject) => {
      firebase.storage().ref().child(`venues/${itemName}`).delete()
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  // Check if images url included in storage before
  CheckImgFromStorage(url: string) {
    if (url.match(/cittiz/)) {
      const splitString = url.split('venues%2F');
      const fileName = splitString[1].split('?alt');
      return fileName[0];
    } else {
      return '';
    }
  }
}

