import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { LoginPanelComponent } from './components/login-panel/login-panel.component';
// Angular material modules
import { SharedModule } from '../../shared/shared.module';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RegistrationComponent } from './components/registration/registration.component';
@NgModule({
  declarations: [LoginPanelComponent, ResetPasswordComponent, RegistrationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    AngularFireAuthModule,
    SharedModule
  ]
})
export class AuthenticationModule { }
