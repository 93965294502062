import { FileService } from '../../../core/services/file.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, pipe } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  HostListener,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from '../../../core/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  // Variables
  image = '';

  // Image upload
  @ViewChild('avatar', { static: false }) avatarFile;
  @ViewChild('avatarUploadLabel', { static: false }) avatarInputLabel;

  /*
   * Call from user profile component
   */

  submitStatus = false;
  @Input() set isSubmittedStatus(status: boolean) {
    if (status === true) {
      this.submitStatus = true;
      // debugger;
      this.UploadToStorage(this.pathFile, this.fileRef, this.fileImg);
    } else {
      this.submitStatus = false;
    }
  }
  calledFromProfile = false;
  @Input() set iscalledFromProfile(status: boolean) {
    if (status === true) {
      this.calledFromProfile = true;
    } else {
      this.calledFromProfile = false;
    }
  }

  /**
   * Call from org edit/create component
   */
  CalledFromOgr = false;
  @Input() set isCalledFromOrg(status: boolean) {
    status === true
      ? (this.CalledFromOgr = true)
      : (this.CalledFromOgr = false);
  }

  // Output
  @Output() emitImage = new EventEmitter();
  // Task
  task: AngularFireUploadTask;

  // progress monitoring
  percentage: Observable<number>;
  snapshot: Observable<any>;

  // Download url
  downloadURL: Observable<string>;

  // State for dropzone toggling on CSS
  isHovering: boolean;

  // Data
  pathFile: any;
  fileRef: any;
  fileImg: any;

  // Notifier service
  private readonly notifier: NotifierService;

  constructor(
    private auth: AuthService,
    private fileService: FileService,
    private afAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  // Authen user
  user: any;
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  UploadToStorage(path, ref, file) {
    if (this.submitStatus === true) {
      if (path !== undefined || ref !== undefined || file !== undefined) {
        // main task
        this.task = this.storage.upload(path, file);
        // progress monitoring
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges();

        // the file download's url
        this.task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = ref.getDownloadURL();
              this.downloadURL.subscribe(url => {
                this.image = url;
                this.fileService.getSingleImgUrl(this.image);
              });
            }),
            tap(snap => {
              // console.log(snap);
              if (snap.bytesTransferred === snap.totalBytes) {
                // do something when finish uploading
                this.notifier.notify('success', 'Upload successfully !');
              }
            })
          )
          .subscribe();
      }
    }
  }

  startUploading(event: FileList) {
    const file = event.item(0);
    this.fileService.getTotalCount(1);
    this.fileImg = file;
    if (file.type.split('/')[0] !== 'image') {
      this.notifier.notify('error', 'Not supported file type !');
      return;
    }
    // storage path
    let path = '';
    if (this.calledFromProfile === true) {
      path = `avatars/${new Date().getTime()}_${file.name}`;
    } else if (this.CalledFromOgr === true) {
      path = `organizations/${new Date().getTime()}_${file.name}`;
    }
    this.pathFile = path;
    const ref = this.storage.ref(path);
    this.fileRef = ref;
    if (this.avatarFile.nativeElement.files[0] === undefined) {
      this.avatarInputLabel.nativeElement.innerHTML = file.name;
    } else {
      this.getUserUploadedImg();
    }
  }
  // Determines if the upload task is active
  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  ngOnInit() {
    this.afAuth.authState.subscribe((user: any) => {
      this.user = user;
    });
  }

  // Show user uploaded result before submitting
  getUserUploadedImg() {
    if (this.avatarFile.nativeElement.value !== undefined) {
      this.avatarInputLabel.nativeElement.innerHTML = this.avatarFile.nativeElement.files[0].name;
    }
  }
}
