import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../../../core/services/auth.service';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
  private readonly notifier: NotifierService;
  constructor(private auth: AuthService, notifierService: NotifierService) {
    this.notifier = notifierService;
  }

  ngOnInit() {
  }

  resetPassword(formValue) {
    const emailReceived: string = formValue.email;
    this.auth.resetPassword(emailReceived).then(() => {
      this.notifier.notify('success', 'A recovery password email has been sent to your mail, please check the mailbox')
    }, error => {
      if (error.code === 'auth/invalid-email' || error.code === 'auth/user-not-found') {
        this.notifier.notify('warning', error.message);
      }
    });
  }

}
