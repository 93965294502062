import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { VenuesManagementService } from '../../../core/services/venues-management.service';

@Component({
  selector: 'app-multiple-img-upload',
  templateUrl: './multiple-img-upload.component.html',
  styleUrls: ['./multiple-img-upload.component.scss']
})
export class MultipleImgUploadComponent implements OnInit, OnChanges, AfterViewInit {

  isHovering: boolean;
  // Array record
  filesDropped = [] ;
  filesFromInput = [];
  files: File[] = [];
  renderImgReceivedOnce = false;
  previewImagesData = [];
  calledFromVenue = false;
  @Input() set isCalledFromVenue(status: boolean) {
    status === true
      ? (this.calledFromVenue = true)
      : (this.calledFromVenue = false);
  }
  isEdittedFromVenue = false;
  @Input() set isVenueEdit(status: boolean) {
    status === true
      ? (this.isEdittedFromVenue = true)
      : (this.isEdittedFromVenue = false);
  }
  // input from parent
  @Input() providedImg = [];
  @Input() objectId = '';
  // emit to parent
  ImgUrlArr = [];
  deletedImg = [];
  @Output() emitImgArray = new EventEmitter();
  @Output() emitImgDeletion = new EventEmitter();
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  pushDroppedFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.filesDropped.push(files.item(i));
      this.files.push(files.item(i));
      this.venueService.checkImages(files.length);
      this.RenderSingleImg(files.item(i));
    }
  }
  async onDrop(files: FileList) {
   await this.pushDroppedFiles(files);
  }
  onSelect(event) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < event.target.files.length; i++) {
      this.filesFromInput.push(event.target.files[i]);
      this.files.push(event.target.files[i]);
      this.venueService.checkImgFromInput(event.target.files.length);
      this.RenderSingleImg(event.target.files[i]);
    }
  }
  constructor(private venueService: VenuesManagementService) {}

  ngOnInit() {
  }
  ngAfterViewInit(): void {
  }

  ngOnChanges() {
    if (this.objectId !== '') {
      if (this.providedImg.length > 0) {
        if (!this.renderImgReceivedOnce) {
          this.providedImg.forEach(item => {
            const obj = {
              file: '',
              result: item
            };
            this.previewImagesData.push(obj);
          });
          this.renderImgReceivedOnce = true;
        }
      }
    }

  }

  ReceivedImg(imgUrl: any) {
    this.ImgUrlArr.push(imgUrl);
    if (this.ImgUrlArr.length === this.files.length) {
      // get enough url, push to parent
      this.venueService.getImgsUrl(this.ImgUrlArr);
    }
  }
  DeleteImg(itemUrl?, fileName?) {
    if (fileName === '') {
      // Old images from storages / from other urls
      const checkImgUrl = this.venueService.CheckImgFromStorage(itemUrl);
      if (checkImgUrl !== '') {
        // from storage
        this.providedImg.forEach(img => {
          if (img.toString().includes(checkImgUrl)) {
            this.providedImg.splice(this.providedImg.indexOf(img, 0), 1);
            this.previewImagesData.splice(this.previewImagesData.findIndex(x => x.result === img), 1);
            this.deletedImg.push(checkImgUrl);
            this.venueService.checkImagesRemove(this.deletedImg);
          }
        });
      } else {
        // from other sources
        this.providedImg.splice(this.providedImg.indexOf(itemUrl));
      }
    } else {
      // New images uploaded/dragged
      const objFound = this.previewImagesData.find(x => x.file === fileName);
      this.previewImagesData.splice(this.previewImagesData.indexOf(objFound), 1);
      // const fileIndex = this.files.find(x => x.file.name === fileName.name);
      const fileIndex = this.files.findIndex(x => x.name === fileName.name);
      this.files.splice(fileIndex, 1);
      this.venueService.checkImgFromInput(this.files.length);
      this.venueService.checkImages(this.files.length);
    }
  }
  RemoveTempImg(item) {
    this.previewImagesData.splice(this.previewImagesData.indexOf(item, 0), 1);
    if (this.files.some(x => x.name === item.file.name)) {
      const itemIndex = this.files.findIndex(x => x.name === item.file.name);
      this.files.splice(itemIndex, 1);
      this.venueService.checkImgFromInput(this.files.length);
      this.venueService.checkImages(this.files.length);
    }
  }

  RenderSingleImg(file: any) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const obj = {
        file,
        result: event.target.result
      };
      this.previewImagesData.push(obj);
    };
    reader.readAsDataURL(file);
  }

}
