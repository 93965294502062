import { IsLoggedInGuard } from './core/guards/is-logged-in.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPanelComponent } from './pages/authentication/components/login-panel/login-panel.component';
import { ResetPasswordComponent } from './pages/authentication/components/reset-password/reset-password.component';
import { RegistrationComponent } from './pages/authentication/components/registration/registration.component';

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'login', component: LoginPanelComponent},
  {path: 'reset-password', component: ResetPasswordComponent,  pathMatch: 'full'},
  {path: 'register', component: RegistrationComponent, pathMatch: 'full'},
  {path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) , canActivate: [IsLoggedInGuard]}
  // {path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
