import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase";
import { Observable, of, BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { User } from "../models/user";
import { AngularFireDatabase } from "@angular/fire/database";
import { FileService } from "./file.service";
@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private db: AngularFireDatabase,
    private router: Router,
    private fileService: FileService
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
    // console.log(this.afAuth.authState);
  }
  user$: Observable<User>;
  userRoles: Array<string>;

  async googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  async emailLoggin(data) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
        .then(
          res => {
            resolve(res);
            return this.updateUserData(res.user);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  private oAuthLogin(provider: any) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(credential => {
        this.updateUserData(credential.user);
        this.router.navigate(['/dashboard/statistic']);
      })
      .catch(err => {
        console.log(err);
      });
  }
  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `users/${user.uid}`
    );
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      roles: {
        viewer: true
      }
    };
    return userRef.set(data, { merge: true });
  }
  updateUserProfile(userFromPortal): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const userRef = firebase
        .firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.uid);
      const user = firebase.auth().currentUser;
      userRef
        .get()
        .then(thisDoc => {
          if (thisDoc.exists) {
            // Listen to images upload
            this.fileService.filesDrop.subscribe(totalNumber => {
              if (totalNumber < 1) {
                // No file attached, update user name only

                userRef
                  .update({
                    displayName: userFromPortal.displayName
                  })
                  .then(res => resolve(res));
              } else {
                // Get the file url
                this.fileService.imgSingle.subscribe(imgUrl => {
                  if (imgUrl !== '') {
                    // Delete user old avatar from storage
                    if (thisDoc.data().photoURL !== null) {
                      const avatarOldFileName = this.CheckImgFromStorage(
                        thisDoc.data().photoURL
                      );
                      if (avatarOldFileName.length > 0) {
                        this.DeleteFromStorage(
                          decodeURI(avatarOldFileName[0]),
                          avatarOldFileName[1]
                        );
                      }
                      // Update user provider data
                      user.updateProfile({
                        displayName: userFromPortal.displayName,
                        photoURL: imgUrl
                      });

                      // update user display name and photoURL in the user collection
                      userRef
                        .update({
                          displayName: userFromPortal.displayName,
                          photoURL: imgUrl
                        })
                        .then(res => resolve(res));
                    } else {
                      // update user display name and photoURL in the user collection
                      userRef
                        .update({
                          photoURL: imgUrl,
                          displayName: userFromPortal.displayName
                        })
                        .then(res => resolve(res));
                    }
                  }
                });
              }
            });
          } else {
            console.log('error');
          }
        })
        .catch(error => {
          reject(error);
          console.log(error);
        });
    });
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    this.router.navigate['/'];
  }

  // sign Up
  emailSignUp(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(
        res => {
          resolve(res);
          return this.updateUserData(res.user);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Reset password help
  resetPassword(email: string) {
    const auth = firebase.auth();
    return new Promise<any>((resolve, reject) => {
      auth.sendPasswordResetEmail(email).then(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  // Delete user old avatar from storage
  CheckImgFromStorage(url: string) {
    let fileName = [];
    let pathName = '';
    if (url.match(/cittiz/)) {
      if (url.match(/test/)) {
        const splitString = url.split('test%2F');
        fileName = splitString[1].split('?alt');
        pathName = 'test';
      } else if (url.match(/avatars/)) {
        const splitString = url.split('avatars%2F');
        fileName = splitString[1].split('?alt');
        pathName = 'avatars';
      }
      return [fileName[0], pathName];
    } else {
      return [];
    }
  }

  DeleteFromStorage(oldAvatar, pathName) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .storage()
        .ref()
        .child(`${pathName}/${oldAvatar}`)
        .delete()
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }
}
