import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {
  MatDialogModule, MatGridListModule,
  MatCardModule, MatFormFieldModule,
  MatRadioModule, MatIconModule, MatSelectModule,
  MatCheckboxModule, MatProgressSpinnerModule,
  MatDatepickerModule, MatToolbarModule,
  MatAutocompleteModule, MatMenuModule,
  MatListModule, MatSlideToggleModule,
  MatInputModule, MatTabsModule, MatSidenavModule,
  MatChipsModule, MatSnackBarModule, MatButtonModule,
  MatTableModule, MatPaginatorModule, MatSortModule,
  MatProgressBarModule, MatGridList
} from '@angular/material';
import { DropzoneDirective } from './directives/dropzone.directive';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { KeysPipe } from './pipes/keys.pipe';
import { RolePipe } from './pipes/role.pipe';
import { MapViewComponent } from './components/map-view/map-view.component';
import { AgmCoreModule } from '@agm/core';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { LinegraphComponent } from './components/charts/linegraph/linegraph.component';
import { MultipleImgUploadComponent } from './components/multiple-img-upload/multiple-img-upload.component';
import { PlaceAutocompleteComponent } from './components/place-autocomplete/place-autocomplete.component';
import { UploadTaskComponent } from './components/multiple-img-upload/upload-task/upload-task.component';
import { ToHtmlPipePipe } from './pipes/to-html-pipe.pipe';

@NgModule({
  declarations: [
    // Directives
    DropzoneDirective,
    // Components
    FileUploadComponent,
    ConfirmDialogComponent,
    MapViewComponent, LinegraphComponent,
    MultipleImgUploadComponent,
    PlaceAutocompleteComponent,
    UploadTaskComponent,
    // Pipes
    KeysPipe, RolePipe,
    FileSizePipe,
    ToHtmlPipePipe
  ],
  imports: [
    CommonModule,
    // Angular Imports
    // BrowserModule,
    // BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    // FontAwesome
    AngularFontAwesomeModule,
    // Angular Material Modules
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatRadioModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatInputModule,
    MatTabsModule,
    MatSidenavModule,
    MatChipsModule,
    MatSnackBarModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatGridListModule,
    // Google Map module
    AgmCoreModule,
    // Carousel
    NgxHmCarouselModule
  ],
  exports: [
    // Angular Shared Components
    FileUploadComponent,
    ConfirmDialogComponent,
    MapViewComponent,
    LinegraphComponent,
    MultipleImgUploadComponent,
    PlaceAutocompleteComponent,
    // Angular Modules
    // BrowserModule,
    // BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    // Font Awesome
    AngularFontAwesomeModule,
    // Angular materials
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatRadioModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatInputModule,
    MatTabsModule,
    MatSidenavModule,
    MatChipsModule,
    MatSnackBarModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatGridListModule,
    // Pipes
    KeysPipe,
    RolePipe,
    ToHtmlPipePipe,
    AgmCoreModule,
    // Carousel
    NgxHmCarouselModule
  ]
})
export class SharedModule { }
