import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone, AfterViewInit, OnChanges } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'app-place-autocomplete',
  templateUrl: './place-autocomplete.component.html',
  styleUrls: ['./place-autocomplete.component.scss']
})
export class PlaceAutocompleteComponent implements OnInit, OnChanges{
  

  @Input() addressVenue: any;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() noData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('address', { static: false }) address: ElementRef;
  autoCompleteInput: string;
  queryWait: boolean;
  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;
  private place: google.maps.places.PlaceResult;
  constructor(
    private mapsApiLoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    // set google maps defaults
    this.zoom = 4;
    this.latitude = 39.8282;
    this.longitude = -98.5795;
    // create search control
    this.searchControl = new FormControl();
 
    // load Places Autocomplete
    this.mapsApiLoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.address.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          // tslint:disable-next-line:prefer-const
          this.place = autocomplete.getPlace();
          // verify result
          if (this.place.geometry === undefined || this.place.geometry === null) {
            return;
          }
          this.setAddress.emit(this.place);
          // set latitude, longitude and zoom
          this.latitude = this.place.geometry.location.lat();
          this.longitude = this.place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  ngOnChanges(): void {

    if (this.addressVenue && this.autoCompleteInput === undefined) {
      this.autoCompleteInput = this.addressVenue.place;
      this.latitude = this.addressVenue.geoPoint.geopoint.latitude;
      this.longitude = this.addressVenue.geoPoint.geopoint.longitude;
    } else {
      this.setCurrentPosition();
    }
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }
  DetectEdit(event) {
    if (event.target.value === '') {
      this.noData.emit(true);
    } else {
      this.noData.emit(false);
    }
  }
}
