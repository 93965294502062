import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../../../core/services/auth.service';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-login-panel',
  templateUrl: './login-panel.component.html',
  styleUrls: ['./login-panel.component.scss']
})
export class LoginPanelComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  public loggedIn = false;
  private readonly notifier: NotifierService;
  constructor(private authService: AuthService, private router: Router, notifierService: NotifierService) {
    this.loggedIn = !!sessionStorage.getItem('user');
    this.notifier = notifierService;
  }

  handleLoginWithEmail(formValue) {
    // console.log(formValue);
    this.authService.emailLoggin(formValue)
      .then(res => {
        if (res.user) {
          // navigate do dashboard
          this.notifier.notify('success', 'Login succesfully !');
          this.router.navigate(['/dashboard/statistic']);
        }
      }, err => {
        // console.log(err);
        if (err.code == "auth/wrong-password") {
          this.notifier.notify('error', err.message);
        }
      });
  }

  // set current user's session
  setCurrentUser(email: string) {
    sessionStorage.setItem('user', email);
    this.loggedIn = true;
  }

  // get currently logged user from session
  getcurrentUser() {
    return sessionStorage.getItem('user') || undefined;
  }
  ngOnInit() {
  }
  loginGoogle() {
    this.authService.googleLogin();
  }
}
