import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { User } from '../models/user';
@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  userRoles: Array<string>;
  constructor(private auth: AuthService, private db: AngularFireDatabase, private afs: AngularFirestore) {
    auth.user$.pipe(
      map(user => {
        return this.userRoles = _.keys(_.get(user, 'roles'));
      })).subscribe();
  }

  // Get data
  async getUsersList() {
    return new Promise<any>((resolve, reject) => {
      firebase.firestore().collection('users').get().then(
        (res) => {
          resolve(res);
        }, error => {
          reject(error);
        }
      );
    });
  }

  getSingleUser(key) {
    return this.afs.collection('users').doc(key).get();
    // return this.db.object('users/' + key);
  }

  get canRead(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }
  get canUpdate(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }
  get canDelete(): boolean {
    const allowed = ['admin'];
    return this.matchingRole(allowed);
  }
  // Helper to determine if any matching roles exists
  private matchingRole(allowedRoles): boolean {
    return !_.isEmpty(_.intersection(allowedRoles, this.userRoles));
  }

  // User actions
  deleteUser(key) {
    if (this.canDelete) {
      return this.db.list('users/' + key).remove()
             .then(() => this.afs.collection('users').doc(key).delete());
    } else {
      console.log('action prevented');
    }
  }

  UpdateRole(user) {
    if (this.canUpdate) {
      const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
      return userRef.set(user, { merge: true });
    }
  }

  UpdateUserJoiningStatus(user, value) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    return userRef.update(
      { has_organization: value }
    );
  }

}
