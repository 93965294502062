import { Pipe, PipeTransform } from '@angular/core';

const FILE_SIZE_UNITS = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Pettabytes', 'Exabytes', 'Zettabytes', 'Yottabytes'];
const FILE_SIZE_UNITS_LONG = ['B','KB','MB', 'GB', 'TB', 'PB', 'EB','ZB','YB'];

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(sizeInBytes: number, longForm:boolean): any {
    const units = longForm ? FILE_SIZE_UNITS_LONG : FILE_SIZE_UNITS;

    let power = Math.round(Math.log(sizeInBytes) / Math.log(1024));
    power = Math.min(power, units.length - 1);

    const size = sizeInBytes/ Math.pow(1024, power);
    const formattedSize = Math.round(size * 100) / 100;
    const unit = units[power];

    return size ? `${formattedSize} ${unit}` : '0';
  }

}
