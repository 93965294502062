import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';

@Pipe({
  name: 'toHtmlPipe'
})
export class ToHtmlPipePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {

  }
  transform(content: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}
